<template>
    <div class="reporting">
        <div style="display: flex;">
          <Headbuttom></Headbuttom>
           
          <div @click="handFromshow"><el-input
          size="small"
          class="dev_dowm"
          placeholder=""
          suffix-icon="el-icon-arrow-down"
          :readonly="true"
          >
        </el-input>
        </div>
       
        </div>
         <div class="attheadfrom" v-if="flag">
            <el-form
                :inline="true"
                size="small"
                :model="searchParams"
                class="demo-form-inline"
            >
                
            <el-form-item label="报餐类型">
                <el-checkbox-group v-model="searchParams.type">
                      <el-checkbox label="早餐" name="type"></el-checkbox>
                      <el-checkbox label="中餐" name="type"></el-checkbox>
                      <el-checkbox label="晚餐" name="type"></el-checkbox>
                      <el-checkbox label="宵夜" name="type"></el-checkbox>
                  </el-checkbox-group>
            </el-form-item>

                <el-form-item>
                <el-button type="primary" @click="onSubmit">{{
                    $t("devtable.cx")
                }}</el-button>
                </el-form-item>
            </el-form>
        </div>


        <!-- 表格 -->
        <div class="tabls">
            <el-table
            border
            :data="tableData"
            style="width: 100%">
                <el-table-column
                    prop="person_count"
                    label="报餐人数"
                    align="center"
                    width="120">
                </el-table-column>
                <el-table-column
                    prop="id"
                    align="center"
                    label="报餐类型"
                    width="100">
                </el-table-column>
                

               

                <el-table-column
                    prop="unit_price"
                    align="center"
                    label="报餐单价">
                    <template slot-scope="scope">
                    
                        <el-input size="small" v-if="scope.row.id==showt && show" v-model="scope.row.unit_price" placeholder="请输入内容" ></el-input> 
                        <span v-else>{{scope.row.unit_price}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="discount"
                    align="center"
                    label="报餐折扣">
                    <template slot-scope="scope">
                        <el-input size="small" v-if="scope.row.id==showt && show" v-model="scope.row.discount" placeholder="请输入内容" ></el-input> 
                        <span v-else>{{scope.row.discount}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="all_price"
                    align="center"
                    label="报餐总费用">
                    <template slot-scope="scope">
                        <el-input size="small" v-if="scope.row.id==showt && show" v-model="scope.row.all_price" placeholder="请输入内容" ></el-input> 
                        <span v-else>{{scope.row.all_price}}</span>
                    </template>
                </el-table-column>

                

              
                <el-table-column
                    align="center"
                    label="操作"
                    width="180">
                    <template slot-scope="scope">
                      
                            <div v-if="!show || scope.row.id != showt">
                                <el-button @click="handleClick(scope.row)" size="medium">修改</el-button>
                            </div>
                            <div v-if="show && scope.row.id == showt">
                                <el-button @click="handleKeep(scope.row)" size="medium">保存</el-button>
                                <el-button @click="handleNoke(scope.row)" size="medium">取消</el-button>
                            </div>
                    </template>
                    
                </el-table-column>
            </el-table>

            <!-- <div style="margin-top:20px">
              <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="1"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="page_size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
            </div> -->
        </div>

    </div>
</template>
<script>
export default {
    data(){
        return{
            flag:false,
            searchParams:{
                type:[]
            },
            tableData: [],
            show:false,
            showt:'',
            total:null,
            page_size:10,
            page_index:1,
            UserInfo:JSON.parse(window.localStorage.getItem('user')),
        }
    },
    mounted(){
        this.handlMeadecl()
    },
    methods:{
        handFromshow(){
            this.flag = !this.flag
        },
        
        onPlus(){

        },
        // 列表
        handlMeadecl(){
            let type=[]
          if(this.searchParams.type){
            this.searchParams.type.forEach(el=>{
                if(el=='早餐'){
                    type.push('0')
                }
                if(el=='中餐'){
                    type.push('1')
                }
                if(el=='晚餐'){
                    type.push('2')
                }
                if(el=='宵夜'){
                    type.push('3')
                }
            })
          }
            let data ={
                method:'co.dinner.accounts.list',
                agent_id:this.UserInfo.agent_id,
                type:type.join(',')
            }
            this.$serve(data).then(res=>{
                if(res.data.code==0){
                  this.tableData = res.data.data
                  this.tableData.forEach(el=>{
                      if(el.id==0){
                          el.id='早餐'
                      }else if(el.id==1){
                          el.id = '中餐'
                      }else if(el.id==2){
                          el.id = '晚餐'
                      }else{
                          el.id='宵夜'
                      }
                  })
                  this.total = res.data.count
                }
            })
        },
        handleClick(row){
            this.showt = row.id
            this.show = true
        },

        handleKeep(row){
            let data={
                method:'co.dinner.accounts.update',
                id:row.id,
                unit_price:row.unit_price,
                discount:row.discount
            }
            this.$serve(data).then(res=>{
              if(res.data.code==0){
                      this.$message({
                          message: "保存成功!",
                          type: "success",
                      });
                      this.show = false
                  }else{
                      this.$message({
                          message: "保存失败!",
                          type: "error",
                      });
                  }
            })
        },

        //取消
        handleNoke(){
          this.show =false
        },

        handleCurrentChange(v){
          this.page_index = v
        //   this.handlMeadecl()
        },
        handleSizeChange(v){
          this.page_size = v
        //   this.handlMeadecl()
        },
        onSubmit(){
          this.handlMeadecl()
        }   

    }
}
</script>

<style scoped>
.reporting{
    padding: 20px;
    background-color: #fff;
}
.dev_dowm{
    margin-left: 30px;
}
.attheadfrom {
    padding: 20px;
    margin-top: 10px;
  }
.tabls{
    padding: 20px;
}
</style>